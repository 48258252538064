import RestHttpClient from '../../../../common/RestHttpClient';
import { HeadCell } from '../../../../common/table/EnhancedTableHead';
import { FinanceCalculation, formatToEuro } from '../../new/finances/FinancesCalculationList';
import { useTranslation } from 'react-i18next';
import React, { Fragment, useEffect, useState } from 'react';
import { EnhancedTable } from '../../../../common/table/EnhancedTable';
import { Box, Button, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material';
import { CompareResult, DealCalculationOverview, IVinParameters, NotificationLocation } from '../../../../generated/ApiClient';
import { DealDetailFinanceDrawer } from './DealDetailFinanceDrawer';
import { Icons } from '../../../../component/icons/Icons';
import { CompareDealWithMainCustomerDialog } from '../creditCheck/CompareDealWithMainCustomerDialog';
import { CreditCheckDialog } from '../creditCheck/CreditCheckDialog';
import { SendToContractManagementSystemDate } from './SendToContractManagementSystemDate';
import { pushNotificationHandler } from '../../../../common/PushNotification';
import { FinancePrintDialog } from './FinancePrintDialog';
import { usePermissions } from '../../../../common/auth/PermissionsContext';
import { useNotifications } from '../../../../common/global/useNotifications';
import { CredibilityCheckValues } from '../../common/financeOverlay/RightDrawerSchema';

interface Props {
  dealId: number;
  isIncludingVat: boolean;
  isValidCustomer: boolean;
  validationErrors: string[];
  calculationId?: number;
  readonly?: boolean;
  modelIsActive: boolean;
  vehicleAgeIsValid: boolean;
  reload?: () => void;
  singleInsuranceId?: number;
  amendedByDealId?: number;
  vinParameters: IVinParameters | undefined;
  credibilityCheck: CredibilityCheckValues
}

export function DealDetailFinances({
  dealId,
  isIncludingVat,
  isValidCustomer,
  validationErrors,
  calculationId,
  readonly,
  modelIsActive,
  vehicleAgeIsValid,
  reload,
  singleInsuranceId,
  amendedByDealId,
  vinParameters,
  credibilityCheck
}: Props) {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEdit =
    hasPermission('FinancingProducts', 'Edit') &&
    hasPermission('Deals', 'Edit') &&
    !readonly &&
    modelIsActive;
  const canViewLocked = hasPermission('LockSalesAid', 'Execute');
  const canPrint =
    hasPermission('FinancingProducts', 'Print') || hasPermission('LeasingProducts', 'Print');
  const canSubmit =
    hasPermission('LeasingProducts', 'Submit') || hasPermission('FinancingProducts', 'Submit');
  const [isDrawerOpened, setIsDrawerOpened] = useState(Boolean(calculationId));
  const [loading, setLoading] = useState(false);
  const [isCompareDealWithMainCustomerOpened, setIsCompareDealWithMainCustomerOpened] =
    useState(false);
  const [isCreditCheckOpened, setIsCreditCheckOpened] = useState(false);
  const [rows, setRows] = useState<FinanceCalculation[]>([]);
  const [compareResults, setCompareResults] = useState<CompareResult[]>([]);
  const [isCalcReadonly, setIsCalcReadonly] = useState(false);
  const [editCalcId, setEditCalcId] = useState<number | undefined>(calculationId);
  const [selectedCalculation, setSelectedCalculation] = useState<string | null>(null);

  const [printCalcId, setPrintCalcId] = useState<number>();
  const [isPrintDialogOpen, setPrintDialogOpen] = useState<boolean>(false);

  const handleRowClick = (dco: DealCalculationOverview) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setEditCalcId(dco.fundingProductId);
    const lockCalc = dco.isSalesAidLocked && !canViewLocked;
    setIsCalcReadonly(dco.isFinancingCalculationReadonly || lockCalc);
    setIsDrawerOpened(true);
  };

  const handlePrintClick = (dco: DealCalculationOverview) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setPrintCalcId(dco.fundingProductId);
    setPrintDialogOpen(true);
  };

  const handleNewClick = () => {
    setEditCalcId(undefined);
    setIsCalcReadonly(false);
    setIsDrawerOpened(true);
  };

  const handleCreditCheckClick = async () => {
    if (!selectedCalculation) return;

    RestHttpClient.getDeal(dealId).then(async (res) => {
      if (res.data.hasSubmittedFinancingCalculations) {
        try {
          setLoading(true);
          const { data } = await RestHttpClient.compareDealWithMainCustomer(dealId);
          setCompareResults(data.areEqual ? [] : data.differences);
          setIsCompareDealWithMainCustomerOpened(!data.areEqual);
          setIsCreditCheckOpened(data.areEqual);
        } catch (err) {
          console.log(err);
          pushNotificationHandler.publish(
            t('deals.edit.compareDealWithMainCustomer.dealCustomerDataUpdateError'),
            'error'
          );
        } finally {
          setLoading(false);
        }
      } else setIsCreditCheckOpened(true);
    });
  };

  const fetchCalculations = async (isInit = false) => {
    try {
      setLoading(true);
      const { data } = await RestHttpClient.getDealCalculationsOverview(dealId);
      let sortedData = data.sort((one, two) =>
        one.calculationNumber > two.calculationNumber ? -1 : 1
      );
      const calculations: FinanceCalculation[] = sortedData.map((dco) => ({
        id: dco.fundingProductId.toString(),
        calculationNumber: dco.calculationNumber,
        financingType: dco.financingTypeName,
        isPrimaryCalculation: dco.isPrimaryCalculation,
        sentDate: dco.sendToContractManagementSystemDate,
        sendToContractManagementSystemDate: (
          <SendToContractManagementSystemDate
            date={dco.sendToContractManagementSystemDate}
            isPrimaryCalculation={dco.isPrimaryCalculation}
            locked={dco.isSalesAidLocked}
            extendedAt={dco.extendedAt}
            
          />
        ),
        duration: dco.duration,
        residualValue: formatToEuro(dco.residualValue, true),
        mileage: dco.mileage !== undefined ? Number(dco.mileage).toLocaleString('de-DE') : '-',
        rateIncludingVAT: formatToEuro(dco.regularInstalment, true),
        serviceInstalment: formatToEuro(dco.serviceInstalment, true),
        downPayment: formatToEuro(dco.downpaymentAbsoltue, true),
        deposit: formatToEuro(dco.deposit, true),
        isLeasing: dco.isLeasing,
        isCredit: dco.isCredit,
        rateCategory: dco.rateCategoryName,
        purchasePrice: formatToEuro(dco.purchasePrice, true),
        icon: (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton onClick={handleRowClick(dco)}>
              {readonly || dco.isFinancingCalculationReadonly || !modelIsActive
                ? Icons.open()
                : Icons.pencil()}
            </IconButton>
            {canPrint && !amendedByDealId && (!dco.isSalesAidLocked || canViewLocked) && (
              <IconButton onClick={handlePrintClick(dco)}>{Icons.print()}</IconButton>
            )}
          </Box>
        ),
        isFuelFinancingType: dco.isFuelFinancingType,
        isCreditCheckAvailable: dco.isCreditCheckAvailable,
        className: dco.isSalesAidLocked ? "locked" : undefined
      }));
      const primCalc = calculations.find((c) => c.isPrimaryCalculation);
      if (primCalc && isInit) {
        setSelectedCalculation(primCalc.id);
      }

      setRows(calculations);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useNotifications(NotificationLocation.Financing);

  useEffect(() => {
    if (!isDrawerOpened) {
      fetchCalculations(true);
    }
    // eslint-disable-next-line
  }, [isDrawerOpened]);

  const headCells: readonly HeadCell<FinanceCalculation>[] = [
    {
      id: 'calculationNumber',
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'financingType',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.financingType'),
    },
    {
      id: 'sendToContractManagementSystemDate',
      align: 'left',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.state'),
    },
    {
      id: 'duration',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.duration'),
    },
    {
      id: 'rateIncludingVAT',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.rate'),
    },
    {
      id: 'serviceInstalment',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.serviceInstalment'),
    },
    {
      id: 'mileage',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.mileage'),
    },
    {
      id: 'residualValue',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.residualValue'),
    },
    {
      id: 'downPayment',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.downPayment'),
    },
    {
      id: 'deposit',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.deposit'),
    },
    {
      id: 'purchasePrice',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.purchasePrice'),
    },
    {
      id: 'icon',
      align: 'right',
      disablePadding: false,
      label: '',
    },
  ];

  const handleCompareDealWithMainCustomerClosed = (isCanceled: boolean) => {
    setIsCompareDealWithMainCustomerOpened(false);
    setIsCreditCheckOpened(!isCanceled);
  };

  const handleCreditCheckClosed = () => {
    setIsCreditCheckOpened(false);
    fetchCalculations();
  };

  const onSelection = (ev: React.SyntheticEvent<unknown>, names: readonly string[]) => {
    if (names.length > 0) {
      setSelectedCalculation(names[0]);
    }
  };

  const isSelectable = (r: FinanceCalculation) => {
    return !loading && !r.sentDate;
  };

  const calculation = selectedCalculation?.length
    ? rows.find((x) => x.id === selectedCalculation)
    : null;
  const isCreditCheckDisabled =
    !isValidCustomer || !calculation?.isCreditCheckAvailable;

  return (
    <>
      {loading && <LinearProgress />}
      <Box sx={{ display: 'flex', height: '80px', alignItems: 'flex-end' }}>
        <Typography variant="h3">{t('deals.new.financesConfiguration.list.financing')}</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1%',
            justifyContent: 'flex-end',
            width: '90%',
            height: '100%',
          }}
        >
          {!isValidCustomer && !!rows.length && (
            <Tooltip
              disableFocusListener
              title={
                validationErrors.length ? (
                  <ul>
                    {validationErrors.map((e) => (
                      <li>{e}</li>
                    ))}
                  </ul>
                ) : (
                  t('customers.invalidCustomer')
                )
              }
            >
              {Icons.alert()}
            </Tooltip>
          )}
          <Button
            type="button"
            sx={{ height: '28px' }}
            color="secondary"
            variant="outlined"
            disabled={loading}
            onClick={reload}
          >
            {t("refresh")}
          </Button>
          <Button
            type="button"
            sx={{ height: '28px' }}
            color="secondary"
            variant="outlined"
            disabled={selectedCalculation == null || isCreditCheckDisabled || !canSubmit || loading}
            onClick={handleCreditCheckClick}
          >
            {t('deals.new.financesConfiguration.list.forCreditCheck')}
          </Button>
          {canEdit && (
            <Button
              disabled={!vehicleAgeIsValid}
              variant="contained"
              type="button"
              sx={{ height: '28px' }}
              onClick={handleNewClick}
            >
              {t('deals.new.financesConfiguration.list.newCalculation')}
            </Button>
          )}
        </Box>
      </Box>
      <EnhancedTable
        rows={rows}
        headCells={headCells}
        hideCheckbox={false}
        variant="endless"
        enableSelection={true}
        enableSingleSelection={true}
        onSelection={onSelection}
        isSelectable={isSelectable}
        defaultSelected={selectedCalculation ? [selectedCalculation] : []}
        defaultOrderBy="sendToContractManagementSystemDate"
      />
      <DealDetailFinanceDrawer
        isDrawerOpened={isDrawerOpened}
        setIsDrawerOpened={setIsDrawerOpened}
        isIncludingVat={isIncludingVat}
        calculationId={editCalcId}
        setCalculationId={setEditCalcId}
        dealId={dealId}
        readonly={!canEdit || isCalcReadonly}
        reload={reload}
        vinParameters={vinParameters}
        credibilityCheck={credibilityCheck}
      />
      {selectedCalculation != null && (
        <Fragment>
          <CompareDealWithMainCustomerDialog
            dealId={dealId}
            compareResults={compareResults}
            onClose={handleCompareDealWithMainCustomerClosed}
            setOpen={setIsCompareDealWithMainCustomerOpened}
            isOpen={isCompareDealWithMainCustomerOpened}
          />

          <CreditCheckDialog
            financialCalculationId={Number(selectedCalculation)}
            isCreditCheckOpened={isCreditCheckOpened}
            onClose={handleCreditCheckClosed}
            setOpen={setIsCreditCheckOpened}
            isOpen={isCreditCheckOpened}
          />
        </Fragment>
      )}
      <FinancePrintDialog
        calculationId={printCalcId}
        isOpen={isPrintDialogOpen}
        setIsOpen={setPrintDialogOpen}
        singleInsuranceId={singleInsuranceId}
      />
    </>
  );
}
